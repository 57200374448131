<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-checkbox-marked-outline
          </v-icon>
          รายการขอเปลี่ยนแปลงสิทธิ์
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <v-spacer></v-spacer>
      </v-card-text>
      <user-table
        :keyword="keyword"
        :type="type"
        ref="otable"
        @approve-request="approveRequest"
        @cancel-request="cancelRequest"
      ></user-table>
    </v-card>
    <!-- Approval Dialog -->
    <approval-dialog ref="adlg"></approval-dialog>

    <!-- dialog -->
    <user-dialog ref="odlg" v-model="userDialog" :type="modify" @inserted="onInserted"></user-dialog>
    <bjp-confirm-dialog ref="confirm" />
  </v-container>
</template>

<script>
import userTable from './components/userTable'
import userDialog from './components/userDialog'
import approvalDialog from './components/approvalDialog'
import { update, destroy } from '@/api/userrolerequest'
import { mapActions } from 'vuex'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.userDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.userDialog = true
    },
    approveRequest(id) {
      let data = {
        is_approved: 1,
        id: id,
      }
      update(data)
        .then(res => {
          this.getRoleApproval()
          this.getList()
        })
        .catch(err => {
          console.log(err)
        })
    },
    cancelRequest(id) {
      this.$refs.adlg.open(id)
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.userDialog = false
      this.$refs.otable.getList()
    },
    ...mapActions('Appfilter', ['getRoleApproval']),
  },
  data() {
    return {
      keyword: '',
      userDialog: false,
      type: 'username',
      types: [
        { text: 'ชื่อผู้ใช้', value: 'username' },
        { text: 'ชื่อ-นามสกุล', value: 'fullname' },
        { text: 'เลข 13 หลัก', value: 'cid' },
      ],
      modify: 'add',
      loading: false,
    }
  },
  created() {
    this.getRoleApproval()
  },
  components: {
    userTable,
    userDialog,
    approvalDialog,
  },
}
</script>

<style></style>
