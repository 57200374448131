<template>
  <v-data-table
    :headers="headers"
    :loading="loading"
    :items="lists"
    :options.sync="options"
    :items-per-page.sync="tableMeta.per_page"
    :server-items-length="tableMeta.total"
    :footer-props="footer"
    class="elevation-1 row-pointer"
    @dblclick:row="rowClick"
  >
    <template v-slot:[`item.plan_date`]="{ item }">
      {{
        moment(item.plan_date)
          .add(543, 'year')
          .format('D MMMYYYY')
      }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small fab @click.stop="approveRequest(item.id)" color="blue darken-2" dark v-on="on" v-bind="attrs">
            <v-icon small>mdi-check</v-icon>
          </v-btn>
        </template>
        <span>อนุมัติ</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small fab @click.stop="cancelRequest(item.id)" color="red" dark v-bind="attrs" v-on="on">
            <v-icon small>mdi-cancel</v-icon>
          </v-btn>
        </template>
        <span>ไม่อนุมัติ</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { list } from '@/api/userrolerequest'
import { mapGetters } from 'vuex'
export default {
  methods: {
    getList() {
      this.loading = true
      const { page, itemsPerPage } = this.options
      const param = {
        page: page,
        limit: itemsPerPage,
        keyword: this.keyword,
        type: this.type,
        start: this.dateStart,
        end: this.dateEnd,
        bureau: this.bureauId,
        division: this.divisionId,
        station: this.stationId,
      }
      list(param)
        .then(res => {
          this.lists = res.data
          let meta = res.meta
          let itemsPerPage = parseInt(meta.per_page)
          this.tableMeta = {
            itemsPerPage: itemsPerPage,
            per_page: itemsPerPage,
            page: meta.current_page,
            total: meta.total,
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    rowClick(e, data) {},
    approveRequest(id) {
      this.$emit('approve-request', id)
    },
    cancelRequest(id) {
      this.$emit('cancel-request', id)
    },
  },
  props: {
    keyword: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  watch: {
    options: {
      handler() {
        this.loading = true
        this.getList()
      },
      deep: true,
    },
    bureauId(v) {
      this.getList()
    },
    divisionId(v) {
      this.getList()
    },
    stationId(v) {
      this.getList()
    },
    datesSelected(v) {
      this.getList()
    },
  },
  created() {
    this.getList()
  },
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'ชื่อ-นามสกุล',
          sortable: false,
          value: 'fullname',
        },

        {
          text: 'สิทธิ์เดิม',
          value: 'role',
          sortable: false,
        },
        {
          text: 'สิทธิ์ที่ขอ',
          value: 'request_role',
          sortable: false,
        },
        {
          text: 'หน่วยที่ขอเปลี่ยน',
          value: 'request_station',
          sortable: false,
        },
        {
          text: 'เหตุผล',
          value: 'reason',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      footer: {
        itemsPerPageOptions: [10, 50, 100, 200],
        showCurrentPage: true,
        showFirstLastPage: true,
      },
      tableMeta: {
        itemsPerPage: 10,
        per_page: 10,
        page: 1,
        total: 0,
      },
      lists: [],
      options: {},
    }
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureaus',
      'bureauId',
      'divisionId',
      'stationId',
      'divisions',
      'stations',
      'dateStart',
      'dateEnd',
      'datesSelected',
    ]),
  },
}
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
