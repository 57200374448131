<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card>
      <v-card-title class="headline">บันทึกข้อมูลการไม่อนุมัติ</v-card-title>
      <v-container fluid>
        <v-form v-model="formValid" ref="form" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                name="remark"
                label="เหตุผล"
                hint="ถ้าไม่อนุมัติกรุณากรอกเหตุผล"
                v-model="approvalData.remark"
                :rules="reqRule"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.prevent.stop="dialog = false">ปิด</v-btn>
        <v-btn color="primary" @click.prevent.stop="save">บันทึก</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { update, destroy } from '@/api/userrolerequest'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  methods: {
    open(id) {
      this.dialog = true
      this.approvalData.id = id
    },
    save() {
      if (!this.$refs.form.validate()) return
      update(this.approvalData)
        .then(res => {
          if (res.success) {
            this.getRoleApproval()
            this.$toast.success({
              title: 'Information',
              message: 'ทำรายการเรียบร้อยแล้ว',
            })
          }
          console.log(res)
        })
        .catch(err => {
          this.$toast.error({
            title: 'Error',
            message: 'เกิดข้อผิดพลาดขึ้นระหว่างทำรายการ',
          })
          console.log(err)
        })
        .finally(() => {
          this.dialog = false
        })
    },
    ...mapActions('Appfilter', ['getRoleApproval']),
  },
  data() {
    return {
      dialog: false,
      approvalData: {
        id: '',
        is_approved: 2,
        remark: '',
      },
      approvalItems: [
        { value: 1, text: 'อนุมัติ' },
        { value: 2, text: 'ไม่อนุมัติ' },
      ],
      reqRule: [v => !!v || 'จำเป็นต้องกรอกข้อมูล'],
      formValid: true,
    }
  },
  computed: {
    ...mapGetters('Appfilter', ['commandApproval']),
  },
}
</script>

<style></style>
